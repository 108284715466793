<!-- =========================================================================================
	File Name: ItemGridView.vue
	Description: Item Component - Grid VIew
	----------------------------------------------------------------------------------------
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
    <!-- <div class="item-grid-view vx-row match-height">
        <div class="vx-col" :class="gridColumnWidth" v-for="item in items" :key="item.objectID"> -->
  <div>
    <vx-card class="grid-view-item mb-2 overflow-hidden" v-on="$listeners">
      <template slot="no-body">

        <!-- ITEM IMAGE -->
        <div v-bind:class="{'out-of-stock':!item.in_stock}" class="item-img-container bg-white  h-48 md:h-64 flex items-center justify-center cursor-pointer" @click="navigate_to_detail_view">
          <img :src="base_image[0].path" :alt="item.name" class="grid-view-img px-4">
          <h2 v-if="!item.in_stock"><strong class="text-danger">Sold Out</strong></h2>
          <div v-if="special_price" class="bg-primary text-white p-1" style="position: absolute; top: 15px; right: 0;">
            <small><feather-icon icon="ZapIcon" color="warning" class="w-3 h-3"></feather-icon>Flash sale
            </small>
          </div>
        </div>
        <div class="item-details px-4">
          <!-- TITLE & DESCRIPTION -->
          <div class="my-1">
            <h6 class="truncate-two-line mb-1 hover:text-primary cursor-pointer" style="font-weight: 400 !important;" @click="navigate_to_detail_view">{{ item.name }}</h6>
          </div>
          <!-- RATING & PRICE -->
          <div class="flex justify-between items-center">
            <div truncate-two-line v-if="special_price">
              <h6 class="text-primary text-xl">{{special_price.price | currency}}</h6>
              <h6> <span class="old_price">{{item.price.amount | currency}}</span>, {{special_price.percentage}}%</h6>
            </div>
            <h6 v-else  class="text-primary text-xl truncate-two-line"><span>{{item.price.amount | currency}}</span> </h6>
          </div>
          <div class="flex justify-between items-center" :class="{ 'pb-2': $route.path == '/'}">
            <div class="flex">
              <star-rating :show-rating="false" :rating="item.stars" :increment=".1" :star-size="15" read-only />
              <span class="text-sm mr-1">({{ item.stars }})</span>
            </div>
            <feather-icon style="cursor: pointer" @click="toggleItemInWishList(item)" icon="HeartIcon" :svgClasses="[{'text-danger fill-current' : isInWishList(item.id)}, 'h-6 w-6']" />
          </div>

        </div>

        <!-- SLOT: ACTION BUTTONS -->
        <template v-if="$route.path != '/'">
          <div class="flex flex-wrap">
            <div :id="'quick_order_'+item.id"
                 :class="{'bg-primary':!isInCart(item.id), 'bg-success':isInCart(item.id)}"
                 class="item-view-secondary-action-btn vs-con-loading__container bg-primary p-3 mt-1 flex flex-grow items-center justify-center text-white cursor-pointer"
                 @click="cartButtonClicked(item)">
              <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4" />

              <span class="text-sm font-semibold ml-2" v-if="isInCart(item.id)">VIEW IN CART</span>
              <span class="text-sm font-semibold ml-2" v-else>Quick Order</span>
            </div>
          </div>
        </template>
      </template>
    </vx-card>
    <vs-prompt
      @cancel="phone=''"
      @accept="saveNumber"
      title="Quick Order"
      accept-text="Ok"
      :is-valid="validNumber"
      :active.sync="activePrompt">
      <div class="con-exemple-prompt">
        <span>Enter you mobile number</span>
        <vs-input placeholder="Mobile number" type="number" vs-placeholder="Mobile number" v-model="phone" class="mt-3 w-full" />
        <span class="text-danger text-sm" v-show="!validNumber">Please enter 11 digit valid number</span>
      </div>
    </vs-prompt>
    <vs-prompt
      title="Quick Order Added"
      accept-text="Place Order"
      buttons-hidden
      :active.sync="activeOk">
      <div class="con-exemple-prompt text-center">
        <feather-icon icon="AlertCircleIcon" class="cursor-pointer ml-1 navbar-fuzzy-search"></feather-icon>
        <h3>আপনার অর্ডারটি নিশ্চিত হয়েছে, ধন্যবাদ।</h3>
        <p>বিস্তারিত জানতে আমরা আপনাকে ফোন করবো (সকাল ৯ টা থেকে রাত্র ১১ টার মধ্যে )</p>
      </div>
    </vs-prompt>
  </div>

       <!--  </div>
    </div> -->
</template>

<script>
  import StarRating from 'vue-star-rating';
  import VueCountdown from '@chenfengyuan/vue-countdown';
export default{
    components:{
      StarRating,
      VueCountdown
    },
    props: {
        item: {
    type: Object,
      required: true
  },
    },
    data(){
        return {
          activePrompt:false,
          quickOrderId:'',
          phone:'',
          activeOk:false,
        }
    },
    computed:{
      validNumber(){
        return (this.phone && this.phone.length == 11);
      },
      isInCart() {
        return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId)
      },
      base_image(){
        return this.item.files.filter(function (f) {
          return f.pivot.zone ==='base_image'
        })
      },
      isInWishList() {
        return (itemId) => this.$store.getters['eCommerce/isInWishList'](itemId)
      },
      special_price(){
        if (this.item.special_price_end){
          var end_date = Date.parse(this.item.special_price_end);
          var today = new Date();
          var percentage = ((this.item.special_price.amount-this.item.price.amount)/this.item.price.amount)*100;
          if (end_date>today){
            return {
              price:this.item.special_price.amount,
              percentage: percentage.toFixed(0),
              remain: end_date-today
            };
          }
          return false;
        }
        return false;
      }

    },
    methods: {
      navigate_to_detail_view() {
        this.$router.push({name: 'product_view', params: {slug: this.item.slug }})
          .catch(() => {})
      },
      toggleItemInWishList(item) {
        this.$store.dispatch('eCommerce/toggleItemInWishList', item)
      },
      saveNumber(){
        if (this.phone && this.phone.length == 11){
          this.$store.commit('UPDATE_USER', {phone: this.phone});
          this.addQuickOrder();
        }

      },
      showResponse(data){
        this.$vs.loading.close("#quick_order_"+this.quickOrderId+" > .con-vs-loading");
        var app = this;
        if (data.errors){
          Object.keys(data.errors).forEach(function (er) {
            app.$vs.notify({
              title: 'Error',
              text:data.errors[er][0],
              color:'danger',
            });
          })
        }else{
          this.$vs.notify({
            title: 'Quick order',
            text:data.message,
            color:data.type,
          });
          if (data.type =='success'){
            this.$swal({
              title:'আপনার অর্ডারটি নিশ্চিত হয়েছে, ধন্যবাদ।',
              text:'বিস্তারিত জানতে আমরা আপনাকে ফোন করবো (সকাল ৯ টা থেকে রাত্র ১১ টার মধ্যে )',
              icon:'success',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText:'Close',
              reverseButtons:true,
              timer:6000,
              customClass:{
                actions:'justify-end',
              },
            });
          }
        }
      },
      addQuickOrder(){
        if (!this.$auth.check()){
          this.$swal({
            title: 'Just give us your mobile number',
            icon: 'info',
            inputPlaceholder: 'Type your mobile number',
            input: 'text',
            showCancelButton: true,
            confirmButtonText:'Place order',
            cancelButtonText:'Cancel',
            reverseButtons:true,
            customClass:{
              actions:'justify-end',
            },
            inputValidator: (value) => {
              return new Promise((resolve) => {
                var regex = RegExp(/\0?1[3456789][0-9]{8}\b/);
                if (regex.test(value)){
                  resolve()
                }else {
                  resolve('Enter valid number :)')
                }
              })
            }
          }).then(
            result => {
              if (result.value){
                this.$vs.loading({
                  container: "#quick_order_"+this.quickOrderId,
                  scale: 0.45
                });
                this.axios.post('quick-orders', {product_id:this.quickOrderId, phone:result.value}).then(({data})=>{
                  this.showResponse(data);
                })
              }
            }
          );
        }else{
          this.$vs.loading({
            container: "#quick_order_"+this.quickOrderId,
            scale: 0.45
          });
          this.axios.post('quick-orders', {product_id:this.quickOrderId, phone:this.$store.state.user.phone}).then(({data})=>{
            this.showResponse(data);
          })
        }

      },
      cartButtonClicked(item) {
        if(this.isInCart(item.id)) this.$root.$emit('showCart')
        else  {
          this.quickOrderId = item.id;
          this.addQuickOrder();
          this.m_pixel_QuickOrder({product:item.name})
        }
      },
    }
}
</script>

<style lang="scss" scoped>
.grid-view-item {
    .grid-view-img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        transition: .35s;
    }

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

        .grid-view-img{
            opacity: 0.9;
        }
    }
}
  .old_price {
    text-decoration: line-through ;
  }
.truncate-two-line {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: pre-wrap;
  line-height: 18px;
  width: 100%;
  font-size: 14px;
  height: 36px;
  text-overflow: ellipsis;
  overflow: hidden;
}
  .out-of-stock img {
    opacity: .4;
  }
  .out-of-stock h2 {
    position: absolute;
  }
</style>
